/* GLOBAL STYLE AND FONTS */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
*{margin:0;padding:0;box-sizing:border-box;font-family:'DM Sans',sans-serif;}
body{overflow-x:hidden;}
img,video,iframe,i,picture{display:block;}
.section,.content{position:relative;}
.content{width:100%;max-width:1300px;margin:auto;position:relative;}
.title{font-family:'Bebas Neue',cursive;}
.title a{font-family:'Bebas Neue',cursive;}
.seo_container{display:none;}
/* COLORS */
:root{
    --navy:#1b3762;
    --navy2:#1f325e;
    --blue:#0fc2ec;
    --blue2:#81c3d7;
    --blue3:#0094ff;
    --yellow:#fdb928;
    --pink:#ea3372;
    --green:#61b847;
}

/* FLOTANTE */
.flotantes{position:fixed;top:50%;right:0;transform:translateY(-50%);z-index:9;background-color:#fdb927;padding:.8rem .5rem;border-radius:1rem 0 0 1rem;}
.flotantes .separator{margin:.6rem auto;height:.1rem;width:100%;background-color:#fff;}
.flotantes_wp img{width:2.2rem;height:auto;margin:auto;}
.flotantes_tickets img{width:3.4rem;height:auto;}

/* NAVBAR */
.navbar{position:fixed;top:0;left:0;width:100%;z-index:9;transition:all .2s ease;}
.navbar.fixed{background-color:var(--navy);height:3.5rem;}
.navbar.fixed .navbar_logo{padding-top:.5rem;padding-bottom:.5rem;}
.navbar.fixed .navbar_options__tickets,.navbar.fixed .navbar_options{height:100%;}
.content_mobile{display:none;}
.content_mobile.new .content_mobile__el{width:100%;}
.content_mobile__el{text-decoration:none;}
.content_mobile__el span{display:block;font-size:.6rem;line-height:.6rem;}
.content_mobile__el:nth-child(2){display:flex;align-items:center;justify-content:center;}

.navbar .content{padding-top:0;padding-bottom:0;width:100%;height:100%;display:flex;align-items:center;}
.navbar_logo{padding-top:1rem;padding-bottom:1rem;}
.navbar_logo, .navbar_logo img{height:100%;width:auto;}
.navbar_options{display:flex;flex-grow:1;justify-content:flex-end;align-items:center;}
.navbar_options__el{text-decoration:none;color:#fff;}
.navbar_options__tickets{text-decoration:none;background-color:var(--yellow);color:var(--navy);display:flex;align-items:center;padding:.2rem 1rem;font-weight:bold;}
.navbar_options__tickets img{height:auto;margin-right:.5rem;}
.navbar_options__menu{width:2rem;height:auto;cursor:pointer;}
.navbar_social:nth-child(5) img{width:2rem;}
.navbar_social:nth-child(6) img{width:1rem;}
.navbar_social:nth-child(7) img{width:2.2rem;}
.navbar_social:nth-child(8) img{width:1.8rem;}
.navbar_social:nth-child(9) img{width:1.5rem;}
/*.navbar_social img{height:2rem;width:auto;}
*/
.lan_container{position:relative;height:auto;}
.lan_container_img{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:2rem;height:auto;}
.lan_container select{height:100%;width:2rem;opacity:0;}

/* SIDEBAR MENU */
.sidebar{position:fixed;top:0;right:0;height:100vh;z-index:10;transform:translateX(100%);transition:all .5s ease;width:100%;display:flex;}
.sidebar.active{transform:translateX(0);}
.sidebar_close{position:absolute;width:1.8rem;height:auto;cursor:pointer;right:1.5rem;top:1.5rem;}
.sidebar_sobrante{flex-grow:1;}
.sidebar_container{height:100%;overflow:scroll;background-color:var(--navy2);width:fit-content;}
.sidebar_container{-ms-overflow-style: none;scrollbar-width: none;overflow-y: scroll;}
.sidebar_container::-webkit-scrollbar {display: none;}
.sidebar_buttons{display:flex;margin-bottom:2rem;}
.sidebar_buttons a{text-align:center;text-decoration:none;transition:all .3s ease;font-weight:bold;}
/*.sidebar_buttons a{display:flex;align-items:center;justify-content:center;text-decoration:none;transition:all .3s ease;font-weight:bold;}
.sidebar_buttons a img{width:1.8rem;margin-right:.3rem;padding-bottom:.1rem;}*/
.sidebar_entradas{background-color:var(--yellow);color:var(--navy);display:flex;justify-content:center;align-items:center;}
.sidebar_entradas:hover{background-color:#da9f21;}
.sidebar_tienda{background-color:var(--blue);color:#fff;}
.sidebar_tienda span{display:block;font-size:.9rem;line-height:.8rem;}
.sidebar_tienda:hover{background-color:#0eb0d6;}
.sidebar_language{display:flex;padding-bottom:1rem;border-bottom:1px solid #fff;}
.sidebar_language__el{text-decoration:none;color:#fff;transition:all .3s ease;}
.sidebar_language__el:hover{color:var(--yellow);}
.sidebar_language__el:last-child{margin-right:0;}
.sidebar_language__el.active{color:var(--yellow);font-weight:bold;}
.sidebar_con{padding-bottom:1rem;border-bottom:1px solid #fff;}
.sidebar_con.solo{padding-bottom:0;}
.sidebar_con:last-child{border-bottom:0;}
.sidebar_con a{display:block;text-decoration:none;transition:all .3s ease;}
.sidebar_con a:hover{background-color:var(--blue);}
.sidebar_con__t1{color:#fff;padding:1rem .5rem;}
.sidebar_con__t2{color:#fff;padding:.2rem 0 .2rem 2.5rem;}
.sidebar_con__t2:last-child{margin-bottom:0;}

/* HEADER */
.header{position:relative;min-height:45vh;}
.home_header{background-color:#314872;}
.header__bg{width:100%;height:auto;}
.header__shadow{position:absolute;top:0;left:0;width:100%;height:70%;z-index:2;background:rgb(255,255,255);background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.9) 100%);}
.header_title{position:absolute;bottom:0;left:0;width:50%;text-align:right;transform:translateX(-100%);}
.header_title__bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:1;opacity:.3;}
.header_title__t1,.header_title_doble{position:relative;color:#fff;z-index:2;}
.header_title.habitats{background-color:var(--navy);}
.header_title.tierra{background-color:#bc955c;}
.header_title.costa{background-color:#006853;}
.header_title.mar{background-color:#0075c9;}
.header_title.default{background-color:var(--yellow);}
.header_title.default .header_title__t1{color:var(--navy);}
.header_title_doble.default{color:var(--navy);}
.header_title_center{position:absolute;top:50%;left:50%;z-index:3;color:#fff;transform:translate(-50%,-50%);}

/* CONTENIDO BACK */
.contenido_back{position:absolute;bottom:0;left:0;width:50%;background-color:var(--yellow);display:flex;justify-content:flex-end;color:var(--navy);}
.contenido_back img{width:2rem;height:auto;transform:rotate(180deg);margin-right:1rem;}
.contenido_back a{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}

/* FOOTER */
.footer{background-color:var(--blue2);}
.footer .content{display:flex;flex-wrap:wrap;justify-content:center;padding-top:2rem;padding-bottom:2rem;}
.footer_con__title{color:var(--navy2);margin-bottom:.2rem;}
.footer_con__el{color:#fff;margin-bottom:.1rem;text-decoration:none;display:block;}
.footer_con_social{display:flex;align-items:center;flex-wrap:wrap;}
.footer_con_social .footer_con__el{margin-bottom:1rem;}
.footer_con_social .footer_con__el img{height:1.5rem;width:auto;margin-right:1rem;}
.footer_con_social .footer_con__el:nth-child(4) img{height:1.7rem;}
.footer_con_social .footer_con__el:last-child img{margin-right:0;}
.footer_con_fund{display:flex;flex-wrap:wrap;}
.footer_con_fund_dos{display:flex;flex-wrap:wrap;align-items:center;}
.footer_con_fund .footer_con__el{width:50%;}
.footer_con_fund_dos .footer_con__el{width:33.3%;}
.footer_con_fund .footer_con__el:first-child{margin-bottom:1rem;}
.footer_con_fund .footer_con__el img{width:calc(100% - 1rem);height:auto;margin-right:1rem;}
.footer_con_fund .footer_con__el:nth-child(3) img{width:calc(50% - 1rem);height:auto;margin-right:1rem;}
.footer_con_fund_dos .footer_con__el:nth-child(1){max-width:8rem;}
.footer_con_fund_dos .footer_con__el img{width:calc(100% - 1rem);height:auto;margin-right:1rem;}
.footer_con_fund_dos .footer_con__el:nth-child(2) img{width:100%}
.footer_con_fund_dos .footer_con__el:nth-child(3) img{margin-left:1rem;}
.copy .content{color:var(--navy2);text-align:center;padding-top:1rem;padding-bottom:1rem;}
.copy a{text-decoration:underline;color:var(--navy2);}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .flotantes_wp img{width:1.8rem;}
    .flotantes_tickets img{width:3rem;}
    .header{min-height:initial;overflow:hidden;}
    .header__shadow{height:80%;}
    .header{margin-top:5.9rem!important;}
    .header_title{width:100%;padding:1.2rem 1rem;position:relative;}
    .header_title__t1,.header_title_doble__t1{font-size:3.2rem;line-height:1.93rem;padding-top:.45rem;text-align:center;}
    .header_title_doble__t2{font-size:2rem;line-height:1.3rem;padding-top:.6rem;text-align:center;}
    .header_title_center{font-size:15vw;}
    
    .content{padding:3rem 1rem;}

    .navbar{height:3.5rem;background-color:var(--navy);}
    .navbar_logo{padding-top:.5rem;padding-bottom:.5rem;}
    .navbar_options__el,.navbar_options__tickets{display:none;}
    .navbar_options__menu{width:2rem;}
    .navbar_social{display:none;}
    .lan_container{margin-right:1rem;}

    .sidebar{width:100%;}
    .sidebar_container{padding:2rem 1rem;width:100%;}
    .sidebar_close{position:initial;margin-left:auto;margin-bottom:1rem;}
    .sidebar_buttons a{padding:.5rem 1rem;width:100%;font-size:1.2rem;}
    .sidebar_language__el{margin-right:.5rem;font-size:1.1rem;}
    .sidebar_con{font-size:1.2rem;}

    .contenido_back{width:100%;padding:1rem;font-size:1.1rem;justify-content:flex-start;position:inherit;}

    .content_mobile{display:flex;}
    /*.content_mobile__el{width:50%;text-align:center;display:flex;align-items:center;justify-content:center;font-size:1.1rem;padding:.5rem 0;font-weight:bold;}
    .content_mobile__el img{height:1.5rem;width:auto;margin-right:.5rem;padding-bottom:.1rem;}*/
    .content_mobile__el{width:50%;text-align:center;font-size:1.1rem;padding:.5rem 0;font-weight:bold;}
    .content_mobile__el:nth-child(1){background-color:var(--blue);color:#fff;}
    .content_mobile__el:nth-child(2){background-color:var(--yellow);color:var(--navy);}

    .footer_con{width:50%;}
    .footer_con:nth-child(-n+4){margin-bottom:1rem;}
    .footer_con__title{font-size:1.3rem;}
    .footer_con__el{font-size:1rem;}
    .footer_con_fund .footer_con__el{width:100%;margin-bottom:.5rem;}
    .footer_con_fund_dos .footer_con__el{width:100%;max-width:10rem;margin-bottom:.5rem;}
    .footer_con_fund_dos .footer_con__el:nth-child(3) img{margin-left:0;}
}

@media (max-width:899px) and (min-width: 600px){
    .content{padding:3rem 2rem;}

    .header{min-height:initial;}

    .header_title{position:relative;width:100%;text-align:center;padding:1.5rem 2rem;}
    .header_title__t1,.header_title_doble__t1{font-size:4rem;line-height:2.5rem;padding-top:.5rem;}
    .header_title_doble__t2{font-size:2.3rem;line-height:1.67rem;padding-top:.8rem;}
    .header_title_center{font-size:4rem;}

    .navbar{height:5.5rem;}
    .navbar_options__el{display:none;}
    .navbar_options__tickets{font-size:1.1rem;margin-right:1.5rem;}
    .navbar_options__tickets img{width:1.5rem;}
    .navbar_social{display:none;}

    .lan_container{margin-right:1.5rem;}

    .sidebar_container{padding:4rem;}
    .sidebar_buttons a{padding:.5rem 1rem;width:24rem;font-size:1.2rem;}
    .sidebar_language__el{margin-right:1rem;}
    .sidebar_language__el,.sidebar_con{font-size:1.2rem;}

    .contenido_back{width:100%;padding:1rem 3rem;font-size:1.1rem;justify-content:flex-start;position:inherit;}

    .footer_con{width:50%;}
    .footer_con:nth-child(-n+4){margin-bottom:1rem;}
    .footer_con__title{font-size:1.8rem;}
    .footer_con__el{font-size:1.1rem;padding-right:.5rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .header_title{padding:1.5rem 2rem;}
    .header_title.doble{padding:1.5rem 2rem;}
    .header_title__t1,.header_title_doble__t1{font-size:4rem;line-height:2.5rem;padding-top:.5rem;}
    .header_title_doble__t2{font-size:2.3rem;line-height:1.67rem;padding-top:.8rem;}
    .header_title_center{font-size:5rem;}

    .content{padding:3rem 1rem;}

    .navbar{height:4.5rem;}
    .navbar_options__el,.navbar_options__tickets,.lan_container{font-size:1.1rem;margin-right:1.2rem;}
    .navbar_social{margin-right:1.2rem;}
    .navbar_social:nth-child(5) img{width:1.8rem;}
    .navbar_social:nth-child(6) img{width:.8rem;}
    .navbar_social:nth-child(7){display:none;}
    .navbar_social:nth-child(8) img{width:1.6rem;}
    .navbar_social:nth-child(9) img{width:1.3rem;}
    .navbar_options__tickets img{width:1.8rem;}
    .lan_container_img{width:1.8rem}
    .navbar_options__menu{width:2rem;}

    .sidebar_container{padding:4rem;}
    .sidebar_buttons a{padding:.5rem 1rem;width:28rem;font-size:1.3rem;}
    .sidebar_language__el{margin-right:1rem;}
    .sidebar_language__el,.sidebar_con{font-size:1.3rem;}

    .contenido_back{font-size:1.5rem;padding:1rem 3rem;}

    .footer_con{width:33%;}
    .footer_con:nth-child(-n+3){margin-bottom:1rem;}
    .footer_con__title{font-size:1.8rem;}
    .footer_con__el{font-size:1.1rem;padding-right:.5rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .home_header{height:100vh;}
    .home_header picture{width:100%;height:100%;}
    .home_header .header__bg{width:100%;height:100%;object-fit:cover;}
    .header_title{padding:2rem 2.5rem;}
    .header_title.doble{padding:1.5rem 2.5rem;}
    .header_title__t1,.header_title_doble__t1{font-size:4.5rem;line-height:2.8rem;padding-top:.6rem;}
    .header_title_doble__t2{font-size:3rem;line-height:1.9rem;padding-top:1rem;}
    .header_title_center{font-size:5rem;;}

    .content{padding:4rem 3rem;}

    .navbar{height:6rem;}
    .navbar_options__el,.navbar_options__tickets,.lan_container{font-size:1.2rem;margin-right:1.4rem;}
    .navbar_social{margin-right:1.4rem;}
    .navbar_options__tickets img{width:1.8rem;}
    .navbar_options__menu{width:2.2rem;}

    .sidebar_container{padding:4rem;}
    .sidebar_buttons a{padding:.5rem 1rem;width:28rem;font-size:1.3rem;}
    .sidebar_language__el{margin-right:1rem;}
    .sidebar_language__el,.sidebar_con{font-size:1.3rem;}

    .contenido_back{font-size:1.5rem;padding:1rem 4rem;}

    .footer_con{width:30%;}
    .footer_con:nth-child(-n+3){margin-bottom:1rem;}
    .footer_con__title{font-size:2rem;}
    .footer_con__el{font-size:1.1rem;}
}

@media (min-width:1400px){
    .home_header{height:100vh;}
    .home_header picture{width:100%;height:100%;}
    .home_header .header__bg{width:100%;height:100%;object-fit:cover;}
    .home_header .header__shadow{height:80%;}
    .header_title{padding:2rem 2.5rem;}
    .header_title.doble{padding:1.5rem 2.5rem;}
    .header_title__t1,.header_title_doble__t1{font-size:5rem;line-height:3.1rem;padding-top:.6rem;}
    .header_title_doble__t2{font-size:3rem;line-height:2.12rem;padding-top:1rem;}
    .header_title_center{font-size:10vw;}
    
    .content{padding:5rem 0rem;}

    .navbar{height:6rem;}

    .sidebar_container{padding:4rem 5rem;}
    .sidebar_buttons a{padding:.5rem 1rem;width:32rem;font-size:1.4rem;}
    .sidebar_language__el{margin-right:1rem;}
    .sidebar_language__el,.sidebar_con{font-size:1.4rem;}

    .navbar_options__el,.navbar_options__tickets,.lan_container{font-size:1.3rem;margin-right:2rem;}
    .navbar_social{margin-right:1.5rem;}
    .navbar_options__tickets img{width:1.8rem;}
    .navbar_options__menu{width:2.2rem;}

    .contenido_back{font-size:1.5rem;padding:1rem 4rem;}

    .footer_con{width:30%;}
    .footer_con:nth-child(-n+3){margin-bottom:1rem;}
    .footer_con__title{font-size:2rem;}
    .footer_con__el{font-size:1.2rem;}
}