/* NUESTRA HISTORIA */
.historia .content{z-index:2;}
.historia__title{color:var(--blue);margin-bottom:1rem;}
.historia__des{color:#636363;}
.historia_bg{position:absolute;object-fit:fill;z-index:0;}
.historia_peces{position:absolute;z-index:1;right:0;bottom:0;}

/* ENTRADAS */
.entradas{display:flex;position:relative;z-index:3;overflow-x:hidden;}
.entradas_img img{width:100%;height:100%;object-fit:cover;transform:translateX(-100%);}
.entradas_des{display:flex;flex-flow:column;justify-content:flex-end;transform:translateX(100%);position:relative;}
.entradas_des a{position:absolute;top:0;left:0;width:100%;height:100%;}
.entradas_des__title{color:var(--navy);}
.entradas_des__des{color:#fff;background-color:var(--yellow);display:flex;align-items:center;}

/* TARIFAS Y HORARIOS */
.tarifas{background-color:var(--blue3);}
.tarifas .content{display:flex;flex-wrap:wrap;color:#fff;}
.tarifas_title span{color:var(--yellow);}
.tarifas_des__t1{margin-bottom:.5rem;}
.tarifas_des__t1:nth-child(2){margin-bottom:1.5rem;}
.tarifas_des__btn{color:var(--navy);font-weight:900;background-color:var(--yellow);display:flex;width:fit-content;align-items:center;padding:.5rem 1rem;}
.tarifas_des__btn img{width:2rem;height:auto;margin-right:.5rem;}

/* EXHIBICIONES */
.exhibiciones{width:100%;position:relative;background-color:var(--yellow);}
.exhibiciones_bg{width:100%;height:auto;}
.exhibiciones_title{position:absolute;bottom:0;left:0;z-index:4;background-color:#fff;color:var(--yellow);transform:translateX(-100%);}
.exhibiciones_title a{position:absolute;top:0;left:0;z-index:2;width:100%;height:100%;}
.exhibiciones_medusa{position:absolute;z-index:4;top:0;right:0;width:auto;}

/*
.exhibiciones_des{background-color:var(--navy2);}
.exhibiciones_des .content{z-index:3;}
.exhibiciones_des__bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:2;opacity:.3;}
.exhibiciones_des_links{display:flex;justify-content:center;position:relative;z-index:2;}
.exhibiciones_des_links__el{text-decoration:underline;color:#fff;}
.exhibiciones_des_sep{width:90%;max-width:750px;height:1px;margin:auto;background:rgb(255,255,255);background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 75%, rgba(0,0,0,0) 100%);position:relative;z-index:2;}
.exhibiciones_des_con{display:flex;justify-content:center;}
.exhibiciones_des_con_el__t1{color:var(--yellow);text-align:right;}
.exhibiciones_des_con_el__t2{color:#fff;}
.exhibiciones_des_con_el__t2:last-child{margin-bottom:0;}
.exhibiciones_des_con_el__icon{width:2rem;height:auto;margin-left:auto;}
.exhibiciones_des__final{color:#fff;text-align:center;margin-top:2rem;}
*/

/* ACTIVIDADES */
.actividades{background-color:#0094ff;}
.actividades .content{display:flex;flex-wrap:wrap;}
.actividades_img, .actividades_des{width:50%;position:relative;}
.actividades_img__tiburon{position:absolute;top:-4rem;left:-4rem;z-index:2;width:25rem;}
.actividades_bg{position:absolute;}
.actividades_des__t1{color:#fff;position:relative;z-index:2;}
.actividades__title{width:50%;position:relative;color:var(--navy);margin-left:auto;transform:translateX(100%);background-color:var(--yellow);}
.actividades__title img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:-1;}
.actividades__title a{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}

/* AVANCES DE OBRA */
.avances{display:flex;flex-wrap:wrap;}
.avances_img img{width:101%;height:101%;object-fit:cover;}
.avances_des__title{background-color:#2f6690;color:#fff;}
.avances_des__des{background-color:#16425b;color:#fff;}
.avances_des__des__t1{margin-bottom:1rem;}
.avances_des__des__t1:nth-child(2){margin-bottom:1.5rem;}
.avances_des__des__btn img{width:2rem;height:auto;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .historia .content{padding-top:3rem;padding-bottom:3rem;text-align:center;}
    .historia__title,.historia__des{width:100%;margin:auto;}
    .historia__title{font-size:3rem;}
    .historia__des{font-size:1.2rem;margin-bottom:.5rem;}
    .historia_bg{width:100%;height:100%;object-fit:contain;top:0;left:0;}
    .historia_peces{display:none;}

    .entradas{flex-wrap:wrap;flex-direction:column-reverse;}
    .entradas_img,.entradas_des{width:100%;}
    .entradas_des__title{padding:2rem 0 0 1rem;font-size:3rem;background-color:var(--yellow);}
    .entradas_des__des{padding:0 1rem 2rem 1rem;font-size:1.2rem;}

    .tarifas_title,.tarifas_des{width:100%;}
    .tarifas_title{font-size:2rem;margin-bottom:1rem;}
    .tarifas_des__t1{font-size:1.2rem;}
    .tarifas_des__btn{font-size:1.1rem;}

    .exhibiciones_title{position:initial;text-align:center;font-size:3rem;padding:1rem 1rem .5rem 1rem;}
    .exhibiciones_medusa{height:calc(100% + 3rem);}

    .exhibiciones_des{overflow:hidden;}
    .exhibiciones_des__bg{width:110%;height:110%;top:-5%;left:-5%;}
    .exhibiciones_des_links__el{font-size:2rem;margin-right:1rem;width:50%;text-align:right;}
    .exhibiciones_des_links__el:nth-child(2){margin-right:0;margin-left:1rem;text-align:left;}
    .exhibiciones_des_sep{margin-top:1rem;margin-bottom:1rem;}
    .exhibiciones_des_con{flex-wrap:wrap;}
    .exhibiciones_des_con_el{width:100%;}
    .exhibiciones_des_con_el__t1,.exhibiciones_des_con_el__t2{font-size:1.1rem;margin-bottom:1rem;}
    .exhibiciones_des_con_el__t1{text-align:left;}
    .exhibiciones_des_con_el__icon{margin-bottom:2rem;margin-left:inherit;}
    .exhibiciones_des__final{font-size:1.8rem;}

    .actividades_img, .actividades_des{width:100%;}
    .actividades__title{position:initial;width:100%;text-align:center;padding:1rem 1rem .5rem 1rem;font-size:3rem;}
    .actividades_des__t1{font-size:1.6rem;padding-bottom:8rem;text-align:center;}
    .actividades_bg{width:80%;left:5%;bottom:6rem;}
    .actividades_img__tiburon{width:15rem;}

    .avances_img,.avances_des{width:100%;}
    .avances_des__title{padding:2rem 2rem 1.7rem 2rem;font-size:1.8rem;}
    .avances_des__des{padding:2rem;font-size:1.1rem;height:100%;}
}

@media (max-width:899px) and (min-width: 600px){
    .historia .content{padding-top:8rem;padding-bottom:13rem;}
    .historia__title,.historia__des{width:100%;margin:auto;}
    .historia__title{font-size:3rem;margin-bottom:1rem;}
    .historia__des{font-size:1.2rem;padding-left:4rem;margin-bottom:1rem;}
    .historia_bg{top:3rem;left:1rem;width:calc(100% - 2rem);height:calc(100% - 6rem);}
    .historia_peces{width:35%;bottom:-4rem;}

    .entradas{margin-top:-6rem;}
    .entradas_img,.entradas_des{width:50%;height:36rem;}
    .entradas_des__title{padding:2rem 0 0 2rem;font-size:3rem;}
    .entradas_des__des{padding:2rem;font-size:1.2rem;height:18rem;}

    .tarifas_title,.tarifas_des{width:50%;}
    .tarifas_title .title{font-size:2rem;max-width:20rem;margin:auto;}
    .tarifas_des{padding-left:2rem;}
    .tarifas_des__t1{font-size:1.2rem;margin-bottom:1rem;}

    .exhibiciones_title{font-size:3.5rem;padding:1.5rem 2rem 1rem 2rem;width:50%;text-align:right;}
    .exhibiciones_medusa{height:calc(100% + 6rem);}

    .exhibiciones_des_links__el{font-size:1.8rem;margin-right:1rem;width:50%;text-align:right;}
    .exhibiciones_des_links__el:nth-child(2){margin-right:0;margin-left:1rem;text-align:left;}
    .exhibiciones_des_sep{margin-top:1rem;margin-bottom:1rem;}
    .exhibiciones_des_con_el{width:50%;padding-right:1rem;}
    .exhibiciones_des_con_el:nth-child(2){padding-right:0;padding-left:1rem;}
    .exhibiciones_des_con_el__t1,.exhibiciones_des_con_el__t2{font-size:1.1rem;margin-bottom:1rem;}
    .exhibiciones_des__final{font-size:2.2rem;}

    .actividades_des__t1{font-size:1.6rem;padding-bottom:12rem;}
    .actividades__title{padding:1.5rem 2rem 1rem 2rem;font-size:3.5rem;}
    .actividades_bg{width:80%;left:5%;bottom:2rem;}
    .actividades_img__tiburon{width:20rem;}

    .avances_img,.avances_des{width:50%;}
    .avances_des__title{padding:1.8rem 2rem 1.4rem 2rem;font-size:2.2rem;}
    .avances_des__des{padding:2rem;font-size:1.1rem;height:100%;}
}

@media (max-width:1199px) and (min-width: 900px){
    .historia .content{padding-top:8rem;padding-bottom:14rem;}
    .historia__title,.historia__des{max-width:650px;margin:auto;}
    .historia__title{font-size:3rem;margin-bottom:1rem;}
    .historia__des{font-size:1.2rem;padding-left:4rem;margin-bottom:1rem;}
    .historia_bg{top:4rem;left:3rem;width:calc(100% - 6rem);height:calc(100% - 8rem);}
    .historia_peces{width:25%;bottom:3rem;}

    .entradas{margin-top:-6rem;}
    .entradas_img,.entradas_des{width:50%;height:24.8rem;}
    .entradas_des__title{padding:2rem 0 0 2rem;font-size:3rem;}
    .entradas_des__des{padding:2rem;font-size:1.2rem;height:13rem;}

    .tarifas_title,.tarifas_des{width:50%;}
    .tarifas_title .title{font-size:2.5rem;width:23rem;margin-left:auto;}
    .tarifas_des{padding-left:2rem;}
    .tarifas_des__t1{font-size:1.2rem;margin-bottom:1rem;}
    .tarifas_des__btn{font-size:1.1rem;}

    .exhibiciones_title{font-size:4rem;width:50%;text-align:right;padding:2rem 4rem 1.5rem 4rem;}
    .exhibiciones_medusa{height:calc(100% + 9rem);}

    .exhibiciones_des_links__el{font-size:1.8rem;margin-right:1rem;width:50%;text-align:right;}
    .exhibiciones_des_links__el:nth-child(2){margin-right:0;margin-left:1rem;text-align:left;}
    .exhibiciones_des_sep{margin-top:1rem;margin-bottom:1rem;}
    .exhibiciones_des_con_el{width:50%;padding-right:1rem;}
    .exhibiciones_des_con_el:nth-child(2){padding-right:0;padding-left:1rem;}
    .exhibiciones_des_con_el__t1,.exhibiciones_des_con_el__t2{font-size:1.2rem;margin-bottom:1rem;}
    .exhibiciones_des__final{font-size:2.2rem;}

    .actividades_des__t1{font-size:1.8rem;max-width:430px;padding-bottom:10rem;}
    .actividades__title{padding:2rem 3rem 1.5rem 3rem;font-size:4rem;}
    .actividades_bg{width:80%;left:5%;bottom:2rem;}
    .actividades_img__tiburon{width:20rem;}

    .avances_img,.avances_des{width:50%;}
    .avances_des__title{padding:1.8rem 2rem 1.4rem 2rem;font-size:2.2rem;}
    .avances_des__des{padding:2rem;font-size:1.2rem;height:100%;}
    .avances_des__des__t1{max-width:400px;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .historia .content{padding-top:8rem;padding-bottom:14rem;}
    .historia__title,.historia__des{max-width:750px;margin:auto;}
    .historia__title{font-size:2.5rem;margin-bottom:1rem;}
    .historia__des{font-size:1.2rem;padding-left:4rem;margin-bottom:1rem;}
    .historia_bg{top:4rem;left:4rem;width:calc(100% - 8rem);height:calc(100% - 8rem);}
    .historia_peces{width:25%;}

    .entradas{margin-top:-6rem;}
    .entradas_img,.entradas_des{width:50%;height:24rem;}
    .entradas_des__title{padding:1rem 2rem;font-size:2.5rem;}
    .entradas_des__des{padding:2rem;font-size:1.2rem;height:12rem;}

    .tarifas_title,.tarifas_des{width:50%;}
    .tarifas_title .title{font-size:2.5rem;max-width:23rem;margin-left:auto;line-height:2.5rem;}
    .tarifas_des{padding-left:2rem;}
    .tarifas_des__t1{font-size:1.2rem;margin-bottom:1rem;}
    .tarifas_des__btn{font-size:1.1rem;}

    .exhibiciones_title{font-size:4rem;width:50%;padding:2rem 3rem 1.5rem 3rem;text-align:right;}
    .exhibiciones_medusa{height:calc(100% + 11rem);}

    .exhibiciones_des_links__el{font-size:2rem;margin-right:1rem;width:50%;text-align:right;}
    .exhibiciones_des_links__el:nth-child(2){margin-right:0;margin-left:1rem;text-align:left;}
    .exhibiciones_des_sep{margin-top:1rem;margin-bottom:1rem;}
    .exhibiciones_des_con_el{width:45%;padding-right:1rem;}
    .exhibiciones_des_con_el:nth-child(2){padding-right:0;padding-left:1rem;}
    .exhibiciones_des_con_el__t1,.exhibiciones_des_con_el__t2{font-size:1.2rem;margin-bottom:1rem;}
    .exhibiciones_des__final{font-size:2.5rem;}

    .actividades_des__t1{font-size:2rem;max-width:450px;padding-bottom:9rem;}
    .actividades__title{padding:2rem 3rem 1.5rem 3rem;font-size:4rem;}
    .actividades_bg{width:calc(100% - 20rem);left:8%;bottom:2rem;}

    .avances_img,.avances_des{width:50%;}
    .avances_des__title{padding:2rem;font-size:2.5rem;}
    .avances_des__des{padding:2rem;font-size:1.2rem;height:100%;}
    .avances_des__des__t1{max-width:400px;}
}

@media (min-width:1400px){
    .historia .content{padding-top:10rem;padding-bottom:17rem;}
    .historia__title,.historia__des{max-width:850px;margin:auto;}
    .historia__title{font-size:3rem;margin-bottom:1rem;}
    .historia__des{font-size:1.5rem;padding-left:8rem;margin-bottom:1rem;}
    .historia_bg{top:5rem;left:7.5rem;width:calc(100% - 15rem);height:calc(100% - 10rem);}
    .historia_peces{width:25%;bottom:-5rem;}

    .entradas{margin-top:-7rem;}
    .entradas_img,.entradas_des{width:50%;height:31.8rem;}
    .entradas_des__title{padding:1rem 2rem;font-size:3rem;}
    .entradas_des__des{padding:3rem;font-size:1.5rem;height:16rem;}

    .tarifas .content{justify-content:center;}
    .tarifas_title,.tarifas_des{width:50%;}
    .tarifas_title .title{font-size:3rem;line-height:3rem;width:28rem;margin-left:auto;}
    .tarifas_des{padding-left:3rem;}
    .tarifas_des__t1{font-size:1.5rem;margin-bottom:1rem;}
    .tarifas_des__btn{font-size:1.2rem;}

    .exhibiciones_title{width:50%;text-align:right;font-size:5rem;line-height:5rem;padding:2rem 4rem 1.5rem 4rem;}
    .exhibiciones_medusa{height:calc(100% + 13rem);}

    .exhibiciones_des_links__el{font-size:2rem;margin-right:1.5rem;width:50%;text-align:right;}
    .exhibiciones_des_links__el:nth-child(2){margin-right:0;margin-left:1.5rem;text-align:left;}
    .exhibiciones_des_sep{margin-top:1.5rem;margin-bottom:1.5rem;}
    .exhibiciones_des_con_el{width:50%;padding-right:1.5rem;}
    .exhibiciones_des_con_el:nth-child(2){padding-right:0;padding-left:1.5rem;}
    .exhibiciones_des_con_el__t1,.exhibiciones_des_con_el__t2{font-size:1.5rem;margin-bottom:1rem;}
    .exhibiciones_des__final{font-size:3rem;}

    .actividades_des__t1{font-size:2.5rem;max-width:550px;padding-bottom:11rem;}
    .actividades__title{padding:2rem 4rem 1.5rem 4rem;font-size:5rem;}
    .actividades_bg{width:70%;left:11%;bottom:2rem;}

    .avances_img,.avances_des{width:50%;}
    .avances_des__title{padding:3rem;font-size:3rem;}
    .avances_des__des{padding:3rem;font-size:1.5rem;height:100%;}
    .avances_des__des__t1{max-width:500px;}
}

@media (max-width:1499px) and (min-width: 1400px) {
    .entradas_des__title{padding:0 3rem;}
    .entradas_des__des{padding:2rem 3rem;}
}

@media (max-width:1599px) and (min-width: 1500px) {
    .entradas_des__title{padding:0 3rem;}
    .entradas_des__des{padding:3rem;}
}

@media (min-width: 1600px) {
    .entradas_des__title{padding:0 0 1rem 5rem;}
    .entradas_des__des{padding:4rem 5rem;}
    .tarifas_des{padding-left:5rem;}
}