/* SOMOS LLENOS DE VIDA */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.vidaTiburon{position:absolute;left:0;top:-3rem;z-index:2;}
.vida_bg_container{position:absolute;top:0;left:0;width:100%;height:100%;z-index:-1;overflow:hidden;}
.vida__bg{position:absolute;height:100%;width:auto;object-fit:cover;top:0;left:0;z-index:-1;}
.vida_title{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;text-align:center;margin-bottom:3rem;}
.vida_title__t1{font-family: "Bebas Neue", sans-serif;font-weight: 400;font-style: normal;margin-bottom:.5rem;font-weight:bold;color:#181c3f;}
.vida_title__sep{width:10rem;height:.25rem;background-color:var(--yellow);}
.vida_elements{display:flex;justify-content:center;align-items:stretch;width:100%;}
.vida_element_link{position:absolute;top:0;left:0;width:100%;height:100%;}
.vida_element{position:relative;width:calc(33.3% - 1rem);padding:2rem;margin-left:.5rem;margin-right:.5rem;text-align:center;color:#fff;display:flex;flex-direction:column;justify-content:center;}
.vida_element{-webkit-box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.30);-moz-box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.30);box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.30);}
.vida_element__img{height:8rem;width:auto;margin:auto;margin-bottom:1rem;}
.vida_element__t1{font-family:"Bebas Neue",sans-serif;font-weight:400;text-align:center;margin:auto;}
.vida_element:nth-child(1){background-color:#fab60c;}
.vida_element:nth-child(2){background-color:#21b7e3;}
.vida_element:nth-child(3){background-color:#b6cc10;}

/* GRAN ACUARIO MAZATLAN */
.acuario.section{display:flex;}
.acuario_img{width:46%;}
.acuario_des{width:64%;background: rgb(23,96,145);background: linear-gradient(45deg, rgba(23,96,145,1) 0%, rgba(22,137,190,1) 100%);color:#fff;}
.acuario_des{display:flex;flex-direction:column;justify-content:center;}
.acuario_des__t1,.acuario_des__t3{font-family:"Bebas Neue",sans-serif;font-weight:400;}
.acuario_des__t4{display:flex;align-items:center;}
.acuario_des__t4 img{height:1rem;width:auto;margin-right:.5rem;}

/* ACTIVIDADES */
.grandiosas .content{overflow:hidden;}
.grandiosas__medusa, .grandiosas__guacamaya{position:absolute;z-index:2;}
.grandiosas__medusa{left:0;top:-5rem;}
.grandiosas__guacamaya{right:0;top:-2rem;}
.grandiosas_title{color:#181c3f;text-align:center;margin-bottom:2rem;}
.grandiosas_title__t1{font-family:"Bebas Neue",sans-serif;font-weight:400;margin-bottom:.2rem;}

/* PLANIFICA */
.planifica .content{display:flex;align-items:center;}
.planifica{background:rgb(31,52,98);background:linear-gradient(45deg, rgba(31,52,98,1) 0%, rgba(23,144,198,1) 100%);}
.planifica__bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;opacity:.1;}
.planifica_des_container{background-color:#21b7e3;color:#fff;text-align:center;margin:auto;}
.planifica_des__t1{font-family:"Bebas Neue",sans-serif;font-weight:400;margin-bottom:.5rem;}
.planifica_des__sep{width:10rem;height:.2rem;background-color:#181c3f;margin:auto;}
.planifica_des__btn{background-color:#181c3f;width:fit-content;margin:auto;text-decoration:none;color:#fff;}
.planifica .pinguino{position:absolute;}
.planifica_pinguino{pointer-events:none;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .vida_element__img{height:6rem;}
    .vida_elements{flex-wrap:wrap;}
    .vida_element{width:100%;padding:2rem 1rem;margin-bottom:1rem;}
    .vidaTiburon{width:14rem;top:5rem;}
    .vida_title__t1{font-size:2.5rem;line-height:2.5rem;letter-spacing:-1px;}
    .vida_element__t1{font-size:2.3rem;line-height:2.3rem;max-width:14rem;}
    .vida_element__t2{font-size:1.3rem;}

    .acuario.section{flex-wrap:wrap;}
    .acuario_des,.acuario_img{width:100%;}
    .acuario_img img, .acuario_img picture{width:100%;height:auto;}
    .acuario_des{padding:2rem 1rem;}
    .acuario_des__t1{font-size:1.5rem;max-width:33rem;margin-bottom:.5rem;}
    .acuario_des__t2{font-size:1rem;max-width:37rem;margin-bottom:.5rem;}
    .acuario_des__t3{font-size:1.4rem;margin-bottom:.2rem;text-align:center;}
    .acuario_des__t4{font-size:1rem;margin-bottom:.5rem;justify-content:center;}
    .acuario_des__t4:last-child{margin-bottom:0!important;}

    .grandiosas__medusa{width:12rem;top:-10rem;}
    .grandiosas__guacamaya{display:none;}
    .grandiosas_title__t1{font-size:3rem;line-height:3rem;}
    .grandiosas_title__t2{font-size:1.2rem;max-width:22rem;margin:auto;}

    .planifica .content{padding:3rem 1rem;}
    .planifica__bg{width:100%;height:100%;}
    .planifica_img{height:calc(100% - 1rem);width:auto;}
    .planifica_des{width:100%;}
    .planifica_img{display:none;}
    .planifica_pinguino{position:absolute;z-index:2;bottom:-2rem;right:0;width:22rem;height:159.45px;overflow:hidden;}
    .planifica_pinguino .pinguino{width:100%;height:115.45px;transform:translate(10rem,1rem);}
    .planifica_des_container{width:100%;padding:2rem 1rem;}
    .planifica_des__t1{font-size:3rem;line-height:3rem;}
    .planifica_des__sep{margin-bottom:2rem;}
    .planifica_des__t2{font-size:1.2rem;margin-bottom:3rem;}
    .planifica_des__btn{padding:.8rem 1.5rem;border-radius:.5rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .vida_element__img{height:6rem;}
    .vida_elements{flex-wrap:wrap;}
    .vida_element{width:calc(50% - 1rem);padding:2rem 1rem;margin-bottom:1rem;}
    .vida_element:nth-child(1){margin-right:.5rem;}
    .vida_element:nth-child(2){margin-right:.5rem;}
    .vida_element:nth-child(3){width:calc(100% - 1rem);padding:2rem 1rem;}
    .vidaTiburon{width:16rem;top:5rem;}
    .vida_title__t1{font-size:3.5rem;line-height:4rem;}
    .vida_element__t1{font-size:2.3rem;line-height:2.3rem;max-width:14rem;}
    .vida_element__t2{font-size:1.3rem;}
    
    .acuario_img img, .acuario_img picture{width:100%;height:100%;object-fit:cover;}
    .acuario_des{padding:3rem 2rem;}
    .acuario_des__t1{font-size:1.5rem;max-width:33rem;margin-bottom:.5rem;}
    .acuario_des__t2{font-size:1rem;max-width:37rem;margin-bottom:.5rem;}
    .acuario_des__t3{font-size:1.4rem;margin-bottom:.2rem;}
    .acuario_des__t4{font-size:1rem;margin-bottom:.5rem;}
    .acuario_des__t4:last-child{margin-bottom:0!important;}

    .grandiosas__medusa{width:13rem;}
    .grandiosas__guacamaya{width:10rem;}
    .grandiosas_title__t1{font-size:3rem;line-height:3rem;}
    .grandiosas_title__t2{font-size:1.2rem;max-width:22rem;margin:auto;}
    
    .planifica .content{padding:3rem 2rem;}
    .planifica_img{height:calc(100% - 1rem);width:auto;}
    .planifica_des{width:100%;}
    .planifica_img{display:none;}
    .planifica_pinguino{position:absolute;z-index:8;bottom:-2rem;right:0;width:25rem;height:178.2px;overflow:hidden;}
    .planifica_pinguino .pinguino{width:100%;height:131.2px;transform:translate(10rem,1rem);}
    .planifica_des_container{width:30rem;padding:2.5rem 1rem;}
    .planifica_des__t1{font-size:3rem;line-height:3rem;}
    .planifica_des__sep{margin-bottom:1.5rem;}
    .planifica_des__t2{font-size:1.2rem;margin-bottom:2rem;}
    .planifica_des__btn{padding:.8rem 1.5rem;border-radius:.5rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .vida_element__img{height:6rem;}
    .vida_element{width:calc(33.3% - 1rem);padding:2rem 1rem;}
    .vida_element:nth-child(1){margin-right:.5rem;}
    .vida_element:nth-child(2){margin-right:.25rem;margin-left:.25rem;}
    .vida_element:nth-child(1){margin-left:.5rem;}
    .vidaTiburon{width:22rem;}
    .vida_title__t1{font-size:3.5rem;line-height:4rem;}
    .vida_element__t1{font-size:2.3rem;line-height:2.3rem;max-width:14rem;}
    .vida_element__t2{font-size:1.2rem;}

    .acuario_img img, .acuario_img picture{width:100%;height:100%;object-fit:cover;}
    .acuario_des{padding:3rem 2rem;}
    .acuario_des__t1{font-size:1.5rem;max-width:33rem;margin-bottom:.5rem;}
    .acuario_des__t2{font-size:1rem;max-width:37rem;margin-bottom:.5rem;}
    .acuario_des__t3{font-size:1.4rem;margin-bottom:.2rem;}
    .acuario_des__t4{font-size:1rem;margin-bottom:.5rem;}
    .acuario_des__t4:last-child{margin-bottom:0!important;}

    .grandiosas__medusa, .grandiosas__guacamaya{width:16rem;}
    .grandiosas_title__t1{font-size:4rem;line-height:4rem;}
    .grandiosas_title__t2{font-size:1.2rem;max-width:22rem;margin:auto;}

    .planifica .content{padding-top:1.5rem;padding-bottom:1.5rem;align-items:flex-end;}
    .planifica_img{height:calc(100% - 1rem);width:auto;}
    .planifica_des{width:55%;}
    .planifica_img{width:45%;}
    .planifica_img .foca{transform:translate(0,6rem);}
    .planifica .pinguino{display:none;}
    .planifica_des_container{width:22rem;padding:2.5rem 1rem;}
    .planifica_des__t1{font-size:3rem;line-height:3rem;}
    .planifica_des__sep{margin-bottom:1.5rem;}
    .planifica_des__t2{font-size:1.1rem;margin-bottom:2rem;}
    .planifica_des__btn{padding:.8rem 1.5rem;border-radius:.5rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .vidaTiburon{width:25rem;}
    .vida_title__t1{font-size:4rem;line-height:4rem;}
    .vida_element__t1{font-size:2.8rem;line-height:2.8rem;max-width:18rem;}
    .vida_element__t2{font-size:1.2rem;}

    .acuario_img img, .acuario_img picture{width:100%;height:100%;object-fit:cover;}
    .acuario_des{padding:3rem;}
    .acuario_des__t1{font-size:1.95rem;max-width:37.5rem;margin-bottom:.5rem;}
    .acuario_des__t2{font-size:1.1rem;max-width:37rem;margin-bottom:.5rem;}
    .acuario_des__t3{font-size:1.5rem;margin-bottom:.2rem;}
    .acuario_des__t4{font-size:1.1rem;margin-bottom:.5rem;}
    .acuario_des__t4:last-child{margin-bottom:0!important;}

    .grandiosas__medusa, .grandiosas__guacamaya{width:18rem;}
    .grandiosas_title__t1{font-size:4rem;line-height:4rem;}
    .grandiosas_title__t2{font-size:1.2rem;max-width:22rem;margin:auto;}

    .planifica .content{padding-top:1.5rem;padding-bottom:1.5rem;align-items:flex-end;}
    .planifica_des{width:55%;}
    .planifica_img{width:45%;}
    .planifica_img .foca{transform:translate(0,6rem);}
    .planifica .pinguino{display:none;}
    .planifica_des_container{width:25rem;padding:3.5rem 1.5rem;}
    .planifica_des__t1{font-size:3.5rem;line-height:3.5rem;}
    .planifica_des__sep{margin-bottom:2rem;}
    .planifica_des__t2{font-size:1.2rem;margin-bottom:3rem;}
    .planifica_des__btn{padding:1rem 2rem;border-radius:.5rem;}
}

@media (min-width:1400px){
    .vidaTiburon{width:28rem;}
    .vida_title__t1{font-size:5rem;line-height:5rem;}
    .vida_element__t1{font-size:3rem;line-height:3rem;max-width:19rem;}
    .vida_element__t2{font-size:1.3rem;}

    .acuario_img img, .acuario_img picture{width:100%;height:100%;object-fit:cover;}
    .acuario_des{padding:4rem;}
    .acuario_des__t1{font-size:2.2rem;max-width:42rem;margin-bottom:.6rem;}
    .acuario_des__t2{font-size:1.2rem;max-width:40rem;margin-bottom:.5rem;}
    .acuario_des__t3{font-size:1.7rem;margin-bottom:.3rem;}
    .acuario_des__t4{font-size:1.2rem;margin-bottom:.5rem;}
    .acuario_des__t4:last-child{margin-bottom:0!important;}

    .grandiosas__medusa, .grandiosas__guacamaya{width:20rem;}
    .grandiosas_title__t1{font-size:5rem;line-height:5rem;}
    .grandiosas_title__t2{font-size:1.3rem;max-width:24rem;margin:auto;}

    .planifica .content{padding-top:1.5rem;padding-bottom:1.5rem;align-items:flex-end;}
    .planifica_img{height:100%;width:auto;}
    .planifica_des{width:55%;}
    .planifica_img{width:45%;}
    .planifica_img .foca{transform:translate(0, 6rem);}
    .planifica .pinguino{display:none;}
    .planifica_des_container{width:29rem;padding:4rem 2rem;}
    .planifica_des__t1{font-size:4rem;line-height:4rem;}
    .planifica_des__sep{margin-bottom:2rem;}
    .planifica_des__t2{font-size:1.3rem;max-width:22rem;margin:auto;margin-bottom:3rem;}
    .planifica_des__btn{font-size:1.2rem;padding:1rem 2rem;border-radius:.5rem;}
}