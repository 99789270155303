.slider-container{width:100%;height:auto;}
.slider_element_container{padding:0 .5rem;}
.slider_element{background-color:#21b7e3;}
.slider_element_des{color:#fff;position:relative;}
.slider_element__t1{font-family:"Bebas Neue",sans-serif;font-weight:400;}
.slider_element__btn{position:absolute;bottom:0;left:50%;transform:translate(-50%,50%);background-color:#fab60c;text-decoration:none;color:#fff;border-radius:.5rem;}
.slick-prev:before{color:#e9b52e;}
.slick-next:before{color:#e9b52e;}

/* RESPONSIVE STYLE */
@media (max-width: 599px) {
    .grandiosas .content{padding:3rem 2rem;}
    .slick-prev:before,.slick-next:before{font-size:1.8rem;}
    .slick-prev{left:-1.5rem;}
    .slick-next{right:-1rem;}
    .slider_element_des{padding:2rem 1rem;min-height:20rem;margin-bottom:2rem;}
    .slider_element__t1{font-size:2.5rem;line-height:2.5rem;max-width:12rem;text-align:center;margin:auto;margin-bottom:1rem;}
    .slider_element__t2{font-size:1rem;margin-bottom:.5rem;}
    .slider_element__t3{font-size:1.1rem;}
    .slider_element__btn{font-size:1rem;padding:.6rem 1.2rem;width:fit-content;min-width:12rem;text-align:center;}
}

@media (max-width:899px) and (min-width: 600px) {
    .grandiosas .content{padding:5rem 4rem;}
    .slick-prev:before,.slick-next:before{font-size:2rem;}
    .slick-prev{left:-2rem;}
    .slick-next{right:-1rem;}
    .slider_element_des{padding:2rem 1rem;min-height:20rem;margin-bottom:2rem;}
    .slider_element__t1{font-size:2rem;line-height:2rem;max-width:12rem;text-align:center;margin:auto;margin-bottom:1rem;}
    .slider_element__t2{font-size:.9rem;margin-bottom:.5rem;}
    .slider_element__t3{font-size:1rem;}
    .slider_element__btn{font-size:1rem;padding:.6rem 1.2rem;width:fit-content;min-width:12rem;text-align:center;}
}

@media (max-width:1199px) and (min-width: 900px) {
    .grandiosas .content{padding-left:3rem;padding-right:3rem;}
    .slick-prev:before,.slick-next:before{font-size:1.8rem;}
    .slick-prev{left:-2rem;}
    .slick-next{right:-1rem;}
    .slider_element_des{padding:2rem 1rem;min-height:20rem;margin-bottom:2rem;}
    .slider_element__t1{font-size:2rem;line-height:2rem;max-width:12rem;text-align:center;margin:auto;margin-bottom:1rem;}
    .slider_element__t2{font-size:.9rem;margin-bottom:1rem;}
    .slider_element__t3{font-size:1rem;}
    .slider_element__btn{font-size:1rem;padding:.8rem 1.5rem;width:fit-content;min-width:13rem;text-align:center;}
}

@media (max-width:1399px) and (min-width: 1200px) {
    .grandiosas .content{padding:4rem 3.5rem;}
    .slick-prev:before,.slick-next:before{font-size:2rem;}
    .slick-prev{left:-2.5rem;}
    .slick-next{right:-1.5rem;}
    .slider_element_des{padding:2rem 1rem;min-height:16rem;margin-bottom:2rem;}
    .slider_element__t1{font-size:2rem;line-height:1.8rem;}
    .slider_element__t2{font-size:.9rem;margin-bottom:1rem;}
    .slider_element__t3{font-size:1.1rem;}
    .slider_element__btn{font-size:1.1rem;padding:.8rem 1.5rem;width:fit-content;min-width:13rem;text-align:center;}
}

@media (min-width:1400px) {
    .grandiosas .content{padding:4rem 3rem;}
    .slick-prev:before,.slick-next:before{font-size:2.2rem;}
    .slick-prev{left:-2.7rem;}
    .slick-next{right:-1.7rem;}
    .slider_element_des{padding:2rem 1rem;min-height:17rem;margin-bottom:2rem;}
    .slider_element__t1{font-size:2rem;line-height:1.8rem;}
    .slider_element__t2{font-size:.9rem;margin-bottom:1rem;}
    .slider_element__t3{font-size:1.2rem;}
    .slider_element__btn{font-size:1.2rem;text-align:center;padding:.8rem 1.5rem;width:fit-content;min-width:14rem;}
}