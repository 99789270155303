/* Entradas */
.entradas{background-color:#81c3d7;}
.entradas_content{display:flex;flex-wrap:wrap;}
.entradas_des, .entradas_opt{width:50%;}
.entradas_opt{display:flex;flex-wrap:wrap;}
.entradas_opt_content{width:100%;/*width:50%;padding-right:.8rem;*/}
/*.entradas_opt_content:nth-child(2){padding-right:0;padding-left:.8rem;}*/
.entradas_opt_title{font-size:1.2rem;color:#fff;margin-bottom:.5rem;}
.entradas_des__t1{color:#3a7ca5;}
.entradas_des__t2,.entradas_des__t3{color:#fff;}
.entradas_des__t3 a{color:#fff;}
.entradas_des__t2:nth-child(3){margin-bottom:1.5rem;}
.entradas_des__btn{background-color:var(--yellow);color:var(--navy);display:flex;width:fit-content;padding:.5rem 1rem;text-decoration:none;margin-top:1.5rem;}
.entradas_des__btn img{width:1.5rem;margin-right:.5rem;}
.donar{display:flex;align-items:center;justify-content:center;}
.donar_btn{background-color:#fff;color:var(--navy);padding:.5rem 1rem;text-decoration:none;margin-right:.8rem;}
.donar_des{color:#3a7ca5;}

/* Entradas mientras
.entradas{background-color:#16425b!important;}
.entradas_des__t1{color:#fff!important;}
.entradas_des__t2{color:#81c3d7!important;}
.accordion_entradas_item:nth-child(odd) .accordion_heading{background-color:#1c5473!important;}
.accordion_heading_entradas{color:#fff!important;} */

/* Especiales */
.especiales{background-color:#16425b;}
.especiales_content{display:flex;}
.especiales_des, .especiales_opt{width:50%;}
.especiales_des{color:#fff;text-align:right;}
.especiales_opt__des{color:#fff;margin-top:.5rem;}
.accordion_especiales_item:nth-child(odd) .accordion_heading{background-color:#214f68;}
.accordion_especiales_item:nth-child(even) .accordion_heading{background-color:#2b5c74;}
.accordion_button_especiales{color:#9acfdf;}

/* Eventos */
.eventos{background-color:#2f6690;}
.eventos_bg{position:absolute;}
.eventos_nina{position:absolute;}
.eventos_des{color:#fff;text-align:right;}
.eventos_des .des{max-width:19rem;margin-left:auto;text-align:right;}
.eventos_content{display:flex;}
.eventos_des, .eventos_opt{width:50%;z-index:3;}
.accordion_heading_eventos{background-color:rgba(255, 255, 255, 10%);color:#81c3d7;}

/* Patrocinador */
.patrocinador_content{display:flex;align-items:center;}
.patrocinador_des,.patrocinador_img{width:50%;}
.patrocinador_des__t1{color:#636363;font-weight:600;margin-bottom:1rem;}
.patrocinador_des__link{color:#16425b;text-decoration:none;font-weight:500;border-bottom:1px solid #636363;margin-left:auto;padding:.2rem 0;}
.patrocinador_des__link:last-child{border-bottom:0;}
.patrocinador_img img{width:20rem;margin:auto;margin-bottom:1rem;}
.patrocinador_img__des{text-align:center;color:#636363;font-weight:600;}

/* Accordion */
.accordion_heading{padding:.5rem 1rem;cursor:pointer;}
.accordion_panel{padding:1rem;color:#fff;}
.accordion_panel a{text-decoration:none;color:#fff;font-weight:bold;}
.accordion_button{display:flex;align-items:center;}
.accordion_title{flex-grow:1;}
.accordion_title span{color:#fff;}
.accordion_price{margin-right:.5rem;}
.accordion_icon{width:1.5rem;height:auto;}
.accordion_entradas_item{border-bottom:1px solid #fff;color:#fff;}
.accordion_entradas_item:last-child{border-bottom:none;}
/*.accordion_entradas_item:nth-child(odd) .accordion_heading{background-color:#9acfdf;}
.accordion_heading_entradas{color:#3a7ca5;}*/
.accordion_heading_entradas{color:#fff;}

/* Responsive style */
@media (max-width: 599px){
    .title{font-size:2rem;line-height:2rem;}

    .entradas_des, .entradas_opt{width:100%;}
    .entradas_opt_content{width:100%;padding:0!important;}
    .entradas_des__t1,.entradas_des__t2{margin-left:initial;}
    .entradas_des__t2{font-size:1.4rem;}
    .entradas_des__t3{font-size:1.2rem;}
    .entradas_des__btn{font-size:1.2rem;margin-left:initial;margin-bottom:1.5rem;}
    .donar_btn{font-size:1.2rem;}

    .especiales_des, .eventos_des{text-align:center;margin-bottom:1rem;}
    .especiales_content, .eventos_content{flex-wrap:wrap;}
    .especiales_des, .eventos_des, .especiales_opt, .eventos_opt{width:100%;}
    .especiales_opt__des{font-size:1.1rem;}
    
    .eventos_content{padding-bottom:0;}
    .eventos_des .des{font-size:1.1rem;text-align:center;margin:auto;max-width:25rem;}
    .eventos_bg{top:45%;transform:translate(-10rem, 0);width:100%;position:relative;}
    .eventos_nina{width:8rem;bottom:2rem;left:1rem;position:relative;margin-top:-5rem;}

    .patrocinador_content{flex-wrap:wrap;}
    .patrocinador_des, .patrocinador_img{width:100%;}
    .patrocinador_des__t1{font-size:1.2rem;max-width:450px;}
    .patrocinador_des__link{font-size:1.2rem;width:22rem;margin-left:initial;}
    .patrocinador_img{margin-top:2rem;}
    .patrocinador_img img{width:16rem;}

    .accordion_heading{font-size:1.1rem;}
    .accordion_panel{font-size:1.1rem;}
    .accordion_title span{display:block;font-size:1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .title{font-size:2.5rem;line-height:2.5rem;}
    
    .entradas_des, .entradas_opt{width:100%;}
    .entradas_opt_content{width:100%;padding:0!important;}
    .entradas_opt_title{font-size:1.5rem;}
    .entradas_des__t1,.entradas_des__t2{max-width:28rem;margin-left:initial;}
    .entradas_des__t2{font-size:1.6rem;}
    .entradas_des__t3{font-size:1.2rem;}
    .entradas_des__btn{font-size:1.2rem;margin-left:initial;margin-bottom:1.5rem;}
    .donar_btn{font-size:1.2rem;}

    .especiales_des, .eventos_des{text-align:center;margin-bottom:1rem;}
    .especiales_content, .eventos_content{flex-wrap:wrap;}
    .especiales_des, .eventos_des, .especiales_opt, .eventos_opt{width:100%;}
    .especiales_opt__des{font-size:1.1rem;}
    
    .eventos_content{padding-bottom:0;}
    .eventos_des .des{font-size:1.2rem;text-align:center;margin:auto;max-width:25rem;}
    .eventos_bg{top:45%;transform:translate(-6rem, 0);width:75%;position:relative;}
    .eventos_nina{width:14rem;bottom:2rem;left:3rem;position:relative;margin-top:-7rem;}

    .patrocinador_content{flex-wrap:wrap;}
    .patrocinador_des, .patrocinador_img{width:100%;}
    .patrocinador_des__t1{font-size:1.2rem;max-width:450px;}
    .patrocinador_des__link{font-size:1.2rem;width:22rem;margin-left:initial;}
    .patrocinador_img{margin-top:2rem;}

    .accordion_heading{font-size:1.3rem;}
    .accordion_panel{font-size:1.2rem;}
    .accordion_title span{font-size:1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .title{font-size:2.2rem;line-height:2.2rem;}

    .entradas_des__t1,.entradas_des__t2{max-width:30rem;padding-right:1rem;}
    .entradas_des__t2{font-size:1.5rem;}
    .entradas_des__t3{font-size:1.1rem;}
    .entradas_des__btn{font-size:1.2rem;margin-bottom:1.5rem;}
    .donar_btn{font-size:1.2rem;}

    .especiales_opt,.eventos_opt{padding-left:3rem;}
    .especiales_des, .eventos_des{width:40%;}
    .especiales_opt, .eventos_opt{width:60%;}
    .especiales_opt__des{font-size:1.1rem;}

    .eventos_content{padding-top:6rem;padding-bottom:6rem;}
    .eventos_des .des{font-size:1.1rem;max-width:21rem;}
    .eventos_bg{top:45%;transform:translate(-10rem, -50%);width:55%;}
    .eventos_nina{width:12rem;bottom:2rem;left:3rem;}

    .patrocinador_content{padding-bottom:7rem;}
    .patrocinador_img{padding-top:3rem;}
    .patrocinador_des__t1{font-size:1.2rem;max-width:450px;margin-left:auto;text-align:right;}
    .patrocinador_des__link{font-size:1.2rem;width:19rem;text-align:right;}

    .accordion_heading{font-size:1.2rem;}
    .accordion_panel{font-size:1.1rem;}
    .accordion_title span{font-size:1rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .title{font-size:2.5rem;line-height:2.5rem;}

    .entradas_des__t1,.entradas_des__t2{max-width:28rem;}
    .entradas_des__t2{font-size:1.6rem;}
    .entradas_des__t3{font-size:1.2rem;}
    .entradas_des__btn{font-size:1.2rem;margin-bottom:1.5rem;}
    .donar_btn{font-size:1.2rem;}

    .especiales_opt,.eventos_opt{padding-left:4rem;}
    .especiales_opt__des{font-size:1.1rem;}

    .eventos_content{padding-top:6rem;padding-bottom:6rem;}
    .eventos_des .des{font-size:1.2rem;max-width:23rem;}
    .eventos_bg{top:45%;transform:translate(-10rem, -50%);width:50%;}
    .eventos_nina{width:15rem;bottom:2rem;left:4rem;}

    .patrocinador_content{padding-bottom:8rem;}
    .patrocinador_img{padding-top:4rem;}
    .patrocinador_des__t1{font-size:1.2rem;max-width:450px;margin-left:auto;text-align:right;}
    .patrocinador_des__link{font-size:1.2rem;width:19rem;text-align:right;}

    .accordion_heading{font-size:1.3rem;}
    .accordion_panel{font-size:1rem;}
    .accordion_title span{font-size:1rem;}
}

@media (min-width:1400px){
    .title{font-size:3rem;line-height:3rem;}

    .entradas_des__t1,.entradas_des__t2{max-width:33rem;}
    .entradas_des__t2{font-size:1.8rem;}
    .entradas_des__t3{font-size:1.2rem;}
    .entradas_des__btn{font-size:1.5rem;margin-bottom:1.5rem;}
    .donar_btn{font-size:1.3rem;}
    .donar_des{font-size:1.1rem;}

    .especiales_opt,.eventos_opt{padding-left:4rem;}
    .especiales_opt__des{font-size:1.2rem;}

    .eventos_content{padding-top:6rem;padding-bottom:6rem;}
    .eventos_des .des{font-size:1.3rem;max-width:25rem;}
    .eventos_bg{top:45%;max-width:42rem;transform:translate(-8rem, -50%);width:50%;}
    .eventos_nina{width:15rem;bottom:2rem;left:6rem;}

    .patrocinador_content{padding-bottom:9rem;}
    .patrocinador_img{padding-top:4.5rem;}
    .patrocinador_img img{width:22rem;}
    .patrocinador_des__t1{font-size:1.4rem;max-width:500px;margin-left:auto;text-align:right;}
    .patrocinador_des__link{font-size:1.3rem;width:22rem;text-align:right;}

    .accordion_heading{font-size:1.4rem;}
    .accordion_panel{font-size:1.2rem;}
    .accordion_title span{font-size:1.1rem;}
}