/* ACTIVIDADES */
.actividades{display:flex;flex-wrap:wrap;color:#fff;}

.actividades_rayas{position:relative;width:60%;background:rgb(39,182,228);background:linear-gradient(180deg, rgba(39,182,228,1) 0%, rgba(11,166,217,1) 65%, rgba(21,94,155,1) 100%);}
.actividades_rayas__bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.actividades_rayas .actividades_rayas_img, .actividades_rayas_img__raya{position:relative;width:100%;height:auto;z-index:2;}
.actividades_rayas .actividades_rayas_img{overflow:hidden;}
.actividades_rayas_title{position:absolute;}
.actividades_rayas_disclaimer{position:absolute;}

.actividades_agapornis_img{position:absolute;overflow:hidden;z-index:2;}
.actividades_agapornis{position:relative;width:40%;background-color:#eea325;z-index:1;}
.actividades_agapornis_title{position:absolute;z-index:1;}
.actividades_agapornis_disclaimer{position:absolute;}

.doble_actividad_con{width:100%;display:flex;}
.doble_actividad_one{width:40%;display:flex;flex-direction:column;}
.doble_actividad_two{width:60%;display:flex;flex-direction:column;}

.actividades_pinguinos{background-color:#1962a8;flex-grow:1;display:flex;align-items:center;justify-content:center;}
.actividades_pinguinos_img{overflow:hidden;}

.actividades_interaccion_pinguinos_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.actividades_interaccion_pinguinos{background-color:#1db5e4;position:relative;}
.actividades_interaccion_pinguinos_title{position:relative;z-index:2;}
.actividades_interaccion_pinguinos_img{width:100%;height:0;overflow:hidden;position:relative;}
.actividades_interaccion_pinguinos_img img{position:absolute;}
.actividades_interaccion_pinguinos_disclaimer{position:absolute;z-index:2;}

.actividades_tour{display:flex;position:relative;}
.actividades_tour picture{width:55%;}
.actividades_tour_img{width:100%;height:auto;}
.actividades_tour_des{width:45%;display:flex;flex-direction:column;justify-content:center;background: rgb(195,209,15);background: linear-gradient(105deg, rgba(195,209,15,1) 0%, rgba(244,232,12,1) 100%);}
.actividades_tour_des_disclaimer{position:absolute;bottom:0;left:calc(55% + 1.5rem);bottom:1rem;}

.actividades_lobos{position:relative;background:rgb(250,203,18);background:linear-gradient(180deg, rgba(250,203,18,1) 0%, rgba(250,203,18,1) 35%, rgba(230,88,82,1) 100%);}
.actividades_lobos_tite{position:absolute;}

.actividades_snorkel{width:100%;display:flex;}
.actividades_snorkel_img{width:40%;}
.actividades_snorkel_img img{width:100%;height:auto;}
.actividades_snorkel_des{width:60%;background:rgb(40,188,235);background:linear-gradient(180deg, rgba(40,188,235,1) 0%, rgba(21,93,154,1) 100%);}
.actividades_snorkel_des{display:flex;flex-direction:column;justify-content:center;position:relative;}
.actividades_snorkel_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.actividades_snorkel_des_t1, .actividades_snorkel_des_t2{position:relative;z-index:2;}

.actividades_buceo{width:100%;display:flex;}
.actividades_buceo_img{width:40%;}
.actividades_buceo_img img{width:100%;height:auto;}
.actividades_buceo_des{width:60%;background-color:#1962a8;}
.actividades_buceo_des{display:flex;flex-direction:column;justify-content:center;}

/* RESPONSIVE STYLE */
@media (max-width: 599px) {
    .actividades_rayas{width:100%;padding-bottom:1rem;}
    .actividades_rayas_title__t1{font-size:6.1vw;}
    .actividades_rayas_title__t2{font-size:15vw;line-height:11vw;}
    .actividades_rayas_img__raya{width:85%;transform:translate(-42%, 0);}
    .actividades_rayas_title{top:48%;left:51%;transform:translate(0, -65%);}
    .actividades_rayas_disclaimer{font-size:2.5vw;left:51%;bottom:15%;}

    .actividades_agapornis{width:100%;}
    .actividades_agapornis_img{position:relative;padding-top:2rem;}
    .actividades_agapornis_img img{width:52%;margin-left:auto;}
    .actividades_agapornis_title__t1{font-size:6.5vw;}
    .actividades_agapornis_title__t2{font-size:11vw;line-height:9vw;}
    .actividades_agapornis_title{top:15%;left:3.5%;}
    .actividades_agapornis_disclaimer{bottom:25%;left:3.5%;font-size:2.5vw;}

    .doble_actividad_con{flex-wrap:wrap;flex-direction:column-reverse;position:relative;padding-bottom:56.2%;}
    .doble_actividad_one, .doble_actividad_two{width:100%;}
    .actividades_lobos{position:absolute;bottom:0;}
    
    .actividades_tour picture{display:none;}
    .actividades_tour_des{width:100%;padding:1.5rem;position:relative;padding-bottom:17%;}
    .actividades_tour_des_t1{font-size:12vw;line-height:11vw;}
    .actividades_tour_des_p{font-size:4.5vw;}
    .actividades_tour_des_disclaimer{font-size:3.5vw;left:1.5rem;bottom:1.5rem;}

    .actividades_pinguinos{padding:2rem 1.5rem;position:relative;}
    .actividades_pinguinos_img{position:absolute;right:0;}
    .actividades_pinguinos_title{width:100%;}
    .actividades_pinguinos_title_t1{font-size:6vw;}
    .actividades_pinguinos_title_t2{font-size:12vw;line-height:11.5vw;}
    .actividades_pinguinos_title_t3{font-size:3vw;max-width:65vw;margin-bottom:.5rem;}
    .actividades_pinguinos_title_disclaimer{font-size:2.5vw;max-width:55vw;}
    .actividades_pinguinos_img{width:70%;top:10%;overflow:hidden;}
    .actividades_pinguinos_img img{width:100%;transform:translate(30%, 0);}

    .actividades_interaccion_pinguinos{display:flex;flex-direction:row-reverse;height:0;padding-top:62%;}
    .actividades_interaccion_pinguinos_img{height:initial;width:65%;position:absolute;top:2rem;left:0;overflow:hidden;height:calc(100% - 2rem);}
    .actividades_interaccion_pinguinos_title{width:60%;position:absolute;top:5%;left:37%;}
    .actividades_interaccion_pinguinos_title_t1{font-size:6.3vw;}
    .actividades_interaccion_pinguinos_title_t2{font-size:12vw;line-height:10vw;}
    .actividades_interaccion_pinguinos_title_p{font-size:3.2vw;line-height:3.5vw;width:54.5vw;}
    .actividades_interaccion_pinguinos_disclaimer{font-size:2.5vw;left:37%;top:58%;}
    .actividades_interaccion_pinguinos_img img{position:relative;transform:scaleX(-1);width:160%;margin-left:-60%;}

    .actividades_lobos_img{width:90%;margin-left:auto;margin-top:-15%;}
    .actividades_lobos_tite{top:5%;left:5%;}
    .actividades_lobos_tite_t1{font-size:6.2vw;}
    .actividades_lobos_tite_t2{font-size:12vw;line-height:11vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_p{font-size:3.5vw;max-width:58vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_disclaimer{font-size:2.5vw;max-width:58vw;}

    .actividades_snorkel{flex-direction:column-reverse;}
    .actividades_buceo{flex-direction:column;}
    .actividades_snorkel_des, .actividades_buceo_des{padding:2rem 1rem;width:100%;}
    .actividades_snorkel_img, .actividades_buceo_img{width:100%;}
    .actividades_snorkel_des_t1, .actividades_buceo_des_t1{font-size:12vw;line-height:12vw;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2, .actividades_buceo_des_t2{font-size:3.5vw;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2:last-child, .actividades_buceo_des_t2:last-child{margin-bottom:0;}
    .actividades_snorkel_img img, .actividades_buceo_img img{width:100%;height:auto;}
}

@media (max-width:899px) and (min-width: 600px) {
    .actividades_rayas{width:100%;padding-bottom:1rem;}
    .actividades_rayas_title__t1{font-size:4.3vw;}
    .actividades_rayas_title__t2{font-size:10.5vw;line-height:9vw;}
    .actividades_rayas_img__raya{width:88%;transform:translate(-35%, 0);}
    .actividades_rayas_title{top:50%;left:58%;transform:translate(0, -65%);}
    .actividades_rayas_disclaimer{font-size:2vw;left:58%;bottom:15%;}

    .actividades_agapornis{width:100%;}
    .actividades_agapornis_img{position:relative;padding-top:3rem;}
    .actividades_agapornis_img img{width:55%;margin-left:auto;}
    .actividades_agapornis_title__t1{font-size:5.3vw;}
    .actividades_agapornis_title__t2{font-size:9.1vw;line-height:3.5rem;}
    .actividades_agapornis_title{top:15%;left:7.5%;}
    .actividades_agapornis_disclaimer{bottom:30%;left:7.5%;font-size:2vw;}

    .doble_actividad_con{flex-wrap:wrap;flex-direction:column-reverse;position:relative;padding-bottom:52.2%;}
    .doble_actividad_one, .doble_actividad_two{width:100%;}
    .actividades_lobos{position:absolute;bottom:0;}

    .actividades_tour picture{width:50%;}
    .actividades_tour_des{width:50%;padding:0 2rem 0 2rem;position:relative;}
    .actividades_tour_des_t1{font-size:7.5vw;line-height:7vw;}
    .actividades_tour_des_p{font-size:2.2vw;max-width:41vw;margin-bottom:10%;}
    .actividades_tour_des_disclaimer{font-size:2vw;left:calc(50% + 2rem);bottom:15%;}
    
    .actividades_pinguinos{padding:3rem 2rem;}
    .actividades_pinguinos_img{position:absolute;right:0;}
    .actividades_pinguinos_title{width:100%;}
    .actividades_pinguinos_title_t1{font-size:4.8vw;}
    .actividades_pinguinos_title_t2{font-size:9vw;line-height:8vw;}
    .actividades_pinguinos_title_t3{font-size:2.2vw;max-width:47.5vw;margin-bottom:.5rem;}
    .actividades_pinguinos_title_disclaimer{font-size:2vw;}
    .actividades_pinguinos_img{width:70%;}
    .actividades_pinguinos_img img{width:100%;transform:translate(30%, 0);}

    .actividades_interaccion_pinguinos{display:flex;flex-direction:row-reverse;height:0;padding-top:50%;}
    .actividades_interaccion_pinguinos_img{height:initial;width:60%;position:absolute;top:2rem;left:0;overflow:hidden;height:calc(100% - 2rem);}
    .actividades_interaccion_pinguinos_title{width:60%;position:absolute;top:2rem;}
    .actividades_interaccion_pinguinos_title_t1{font-size:5.3vw;}
    .actividades_interaccion_pinguinos_title_t2{font-size:10vw;line-height:9vw;}
    .actividades_interaccion_pinguinos_title_p{font-size:2.2vw;width:51.5vw;}
    .actividades_interaccion_pinguinos_disclaimer{font-size:2vw;left:40%;top:60%;}
    .actividades_interaccion_pinguinos_img img{position:relative;transform:scaleX(-1);width:145%;margin-left:-45%;}

    .actividades_lobos_img{width:85%;margin-left:auto;margin-top:-15%;}
    .actividades_lobos_tite{top:2rem;left:3rem;}
    .actividades_lobos_tite_t1{font-size:5.1vw;}
    .actividades_lobos_tite_t2{font-size:10vw;line-height:8vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_p{font-size:2.5vw;max-width:58vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_disclaimer{font-size:2vw;max-width:58vw;}

    .actividades_snorkel_des, .actividades_buceo_des{padding:2rem 1rem;}
    .actividades_snorkel_des_t1, .actividades_buceo_des_t1{font-size:6vw;line-height:5vw;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2, .actividades_buceo_des_t2{font-size:2vw;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2:last-child{margin-bottom:0;}
    .actividades_snorkel_img img, .actividades_buceo_img img{width:100%;height:100%;object-fit:cover;}
}

@media (max-width:1199px) and (min-width: 900px) {
    .actividades_rayas{padding-top:4rem;}
    .actividades_rayas_title__t1{font-size:2rem;}
    .actividades_rayas_title__t2{font-size:3.5rem;line-height:3rem;}
    .actividades_rayas_img__raya{width:130%;transform:translate(-39%, 8%);}
    .actividades_rayas_title{top:20%;right:14%;}
    .actividades_rayas_disclaimer{font-size:.7rem;bottom:1rem;right:.1rem;max-width:10rem;}

    .actividades_agapornis_title{top:10%;left:12%;}
    .actividades_agapornis_title__t1{font-size:2rem;;}
    .actividades_agapornis_title__t2{font-size:3.5rem;line-height:3rem;}
    .actividades_agapornis_img img{width:calc(100% + 1rem);margin-left:-1rem;}
    .actividades_agapornis_img{bottom:0;}
    .actividades_agapornis_disclaimer{bottom:.5rem;left:.5rem;font-size:.7rem;}

    .actividades_pinguinos_img{display:none;}
    .actividades_pinguinos{padding:1rem;}
    .actividades_pinguinos_title_t1{font-size:2rem;}
    .actividades_pinguinos_title_t2{font-size:3.5rem;line-height:3.5rem;}
    .actividades_pinguinos_title_t3{font-size:1rem;max-width:24rem;margin-bottom:.5rem;}
    .actividades_pinguinos_title_disclaimer{font-size:.7rem;}

    .actividades_interaccion_pinguinos_title{padding:2rem 1rem 0 1rem;}
    .actividades_interaccion_pinguinos_title_t1{font-size:2rem;}
    .actividades_interaccion_pinguinos_title_t2{font-size:3.5rem;line-height:3.5rem;}
    .actividades_interaccion_pinguinos_title_p{font-size:1rem;max-width:25.5rem;}
    .actividades_interaccion_pinguinos_img{padding-bottom:100%;}
    .actividades_interaccion_pinguinos_img img{width:140%;height:auto;top:0;left:-10%;}
    .actividades_interaccion_pinguinos_disclaimer{font-size:.7rem;bottom:1rem;left:.6rem;max-width:10rem;}

    .actividades_tour_des{padding:1.5rem 1rem;}
    .actividades_tour_des_t1{font-size:3.5rem;line-height:3.5rem;}
    .actividades_tour_des_p{font-size:1rem;margin-bottom:.5rem;}
    .actividades_tour_des_disclaimer{font-size:.7rem;}

    .actividades_lobos{padding-top:1.4rem;}
    .actividades_lobos_tite{top:13%;left:9%;}
    .actividades_lobos_tite_t1{font-size:2rem;}
    .actividades_lobos_tite_t2{font-size:3.5rem;line-height:3.5rem;}
    .actividades_lobos_tite_p{font-size:1rem;max-width:20rem;margin-bottom:.5rem;}
    .actividades_lobos_tite_disclaimer{font-size:.7rem;}
    .actividades_lobos_img{width:100%;}
    .actividades_lobos_img img{width:100%;margin-left:auto;}

    .actividades_snorkel_des, .actividades_buceo_des{padding:2rem;}
    .actividades_snorkel_des_t1, .actividades_buceo_des_t1{font-size:3rem;line-height:3rem;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2, .actividades_buceo_des_t2{font-size:1rem;margin-bottom:.8rem;}
    .actividades_snorkel_des_t2:last-child{margin-bottom:0;}
    .actividades_snorkel_img img, .actividades_buceo_img img{width:100%;height:100%;object-fit:cover;}
}

@media (max-width:1099px) and (min-width: 999px) {
    .actividades_lobos_img{width:90%;margin-left:auto;}
    .actividades_lobos_tite_p{max-width:25rem;}
}

@media (max-width:1199px) and (min-width: 1100px) {
    .actividades_lobos_img{width:80%;margin-left:auto;}
    .actividades_lobos_tite_p{font-size:1.1rem;max-width:28rem;}
}

@media (max-width:1399px) and (min-width: 1200px) {
    .actividades_rayas{padding-top:6rem;}
    .actividades_rayas_title__t1{font-size:3.5vw;}
    .actividades_rayas_title__t2{font-size:6.5vw;line-height:4.5vw;}
    .actividades_rayas_img__raya{width:120%;top:10%;left:-10%}
    .actividades_rayas_title{top:19.5%;right:16.8%;}
    .actividades_rayas_disclaimer{font-size:1.05vw;bottom:1rem;right:1rem;}

    .actividades_agapornis_title{top:5%;left:9%;}
    .actividades_agapornis_title__t1{font-size:3.2vw;}
    .actividades_agapornis_title__t2{font-size:5.5vw;line-height:4vw;}
    .actividades_agapornis_img img{width:calc(100% + 2rem);margin-left:-2rem;}
    .actividades_agapornis_img{bottom:0;}
    .actividades_agapornis_disclaimer{bottom:1rem;left:1rem;font-size:1.05vw;}

    .actividades_pinguinos_title{width:100%;padding:3rem 1.5rem;}
    .actividades_pinguinos_img{display:none;}
    .actividades_pinguinos_title_t1{font-size:3.55vw;}
    .actividades_pinguinos_title_t2{font-size:7vw;line-height:7vw;}
    .actividades_pinguinos_title_t3{font-size:1.4vw;max-width:30.5vw;margin-bottom:.5rem;}
    .actividades_pinguinos_title_disclaimer{font-size:1.1vw;max-width:30.5vw;}

    .actividades_interaccion_pinguinos_title{padding:2rem 1.5rem 0 1.5rem;}
    .actividades_interaccion_pinguinos_title_t1{font-size:2.9vw;}
    .actividades_interaccion_pinguinos_title_t2{font-size:5.5vw;line-height:4vw;}
    .actividades_interaccion_pinguinos_title_p{font-size:1.4vw;max-width:32.8vw;}
    .actividades_interaccion_pinguinos_img{padding-bottom:100%;}
    .actividades_interaccion_pinguinos_img img{width:140%;height:auto;top:0;left:-10%;}
    .actividades_interaccion_pinguinos_disclaimer{font-size:.85vw;bottom:1rem;left:.8rem;max-width:15vw;}

    .actividades_tour_des{padding:1.5rem;}
    .actividades_tour_des_t1{font-size:4.5vw;line-height:4vw;}
    .actividades_tour_des_p{font-size:1.4vw;margin-bottom:.5rem;}
    .actividades_tour_des_disclaimer{font-size:1vw;max-width:20vw;}

    .actividades_lobos{padding-top:2rem;}
    .actividades_lobos_tite{top:12%;left:10%;}
    .actividades_lobos_tite_t1{font-size:3.4vw;}
    .actividades_lobos_tite_t2{font-size:6.5vw;line-height:4.5vw;}
    .actividades_lobos_tite_p{font-size:1.5vw;max-width:35vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_disclaimer{font-size:1.1vw;max-width:29vw;}
    .actividades_lobos_img{width:100%;}
    .actividades_lobos_img img{width:55vw;margin-left:auto;}

    .actividades_snorkel_des, .actividades_buceo_des{padding:2rem;}
    .actividades_snorkel_des_t1, .actividades_buceo_des_t1{font-size:3.5rem;line-height:3.5rem;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2, .actividades_buceo_des_t2{font-size:1.1rem;margin-bottom:1rem;}
    .actividades_snorkel_des_t2:last-child{margin-bottom:0;}
}

@media (min-width:1400px) {
    .actividades_rayas{padding-top:6rem;}
    .actividades_rayas_title__t1{font-size:3.5vw;}
    .actividades_rayas_title__t2{font-size:6.5vw;line-height:4.5vw;}
    .actividades_rayas_img__raya{width:120%;transform:translate(-34%, 8%);}
    .actividades_rayas_title{top:17.5%;right:17.2%;}
    .actividades_rayas_disclaimer{font-size:1.05vw;bottom:1rem;right:1rem;}

    .actividades_agapornis_title{top:3%;left:9%;}
    .actividades_agapornis_title__t1{font-size:3.2vw;}
    .actividades_agapornis_title__t2{font-size:5.5vw;line-height:4vw;}
    .actividades_agapornis_img img{width:calc(100% + 2rem);margin-left:-2rem;}
    .actividades_agapornis_img{bottom:0;}
    .actividades_agapornis_disclaimer{bottom:1rem;left:1rem;font-size:1.05vw;}

    .actividades_pinguinos_title{width:100%;padding:3rem;}
    .actividades_pinguinos_img{display:none;}
    .actividades_pinguinos_title_t1{font-size:3.55vw;}
    .actividades_pinguinos_title_t2{font-size:7vw;line-height:7vw;}
    .actividades_pinguinos_title_t3{font-size:1.4vw;max-width:30.5vw;margin-bottom:.5rem;}
    .actividades_pinguinos_title_disclaimer{font-size:1.1vw;max-width:30.5vw;}

    .actividades_interaccion_pinguinos_title{padding:3rem 3rem 0 3rem;}
    .actividades_interaccion_pinguinos_title_t1{font-size:2.9vw;}
    .actividades_interaccion_pinguinos_title_t2{font-size:5.5vw;line-height:4vw;}
    .actividades_interaccion_pinguinos_title_p{font-size:1.4vw;max-width:32.8vw;}
    .actividades_interaccion_pinguinos_img{padding-bottom:100%;}
    .actividades_interaccion_pinguinos_img img{width:140%;height:auto;top:0;left:-10%;}
    .actividades_interaccion_pinguinos_disclaimer{font-size:.85vw;bottom:1rem;left:.8rem;max-width:15vw;}

    .actividades_tour_des{padding:2rem;}
    .actividades_tour_des_t1{font-size:4.5vw;line-height:4vw;}
    .actividades_tour_des_p{font-size:1.4vw;margin-bottom:.5rem;}
    .actividades_tour_des_disclaimer{font-size:1vw;max-width:20vw;}

    .actividades_lobos{padding-top:2rem;}
    .actividades_lobos_tite{top:12%;left:10%;}
    .actividades_lobos_tite_t1{font-size:3.4vw;}
    .actividades_lobos_tite_t2{font-size:6.5vw;line-height:4.5vw;}
    .actividades_lobos_tite_p{font-size:1.5vw;max-width:35vw;margin-bottom:.5rem;}
    .actividades_lobos_tite_disclaimer{font-size:1.1vw;max-width:29vw;}
    .actividades_lobos_img{width:100%;}
    .actividades_lobos_img img{width:55vw;margin-left:auto;}

    .actividades_snorkel_des, .actividades_buceo_des{padding:3.5vw;}
    .actividades_snorkel_des_t1, .actividades_buceo_des_t1{font-size:4.5vw;line-height:4vw;margin-bottom:.5rem;}
    .actividades_snorkel_des_t2, .actividades_buceo_des_t2{font-size:1.4vw;margin-bottom:1rem;}
    .actividades_snorkel_des_t2:last-child{margin-bottom:0;}
}