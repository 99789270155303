/* Actividades */
.actividades_title{position:absolute;background-color:#10c2ec;width:52%;color:#fff;transform:translate(-100%, -100%);opacity:0;}
.actividades_title_sep{position:relative;width:100%;height:1px;background-color:#fff;}

.actividad:nth-child(4n+1){background-color:#2e668f;}
.actividad:nth-child(4n+2){background-color:#3a7ca5;}
.actividad:nth-child(4n+3){background-color:#15425b;}
.actividad:nth-child(4n+4){background-color:#81c3d6;}
.actividad{display:flex;align-items:center;justify-content:center;flex-wrap:wrap;}
.actividad_image{width:52%;}
.actividad_image img{width:100%;height:auto;}
.actividad_des{width:48%;}
.actividad:nth-child(even) .actividad_des_title{color:#15425b;}
.actividad:nth-child(odd) .actividad_des_title{color:#fff;}
.actividad_des_des{color:#fff;}
.actividad_des_des a{text-decoration:underline;color:#fff;}

/* RESPONSIVE STYLE */
@media (max-width: 599px) {
    .actividades_title{position:relative;transform:translateY(0)!important;width:100%;padding:2rem 1rem;}
    .actividades_title_title{font-size:2rem;line-height:2rem;}
    .actividades_title_sep{margin:.3rem 0;}
    .actividades_title_des{font-size:1.1rem;}

    .actividad{flex-direction:column;}
    .actividad:nth-child(odd){flex-direction:column-reverse;}
    .actividad_image{width:100%;}
    .actividad_des{width:100%;padding:2rem 1rem;}
    .actividad_des_title{font-size:2rem;line-height:2rem;margin-bottom:.5rem;}
    .actividad_des_des{font-size:1rem;margin-bottom:.5rem;overflow-wrap:break-word;}
}

@media (max-width:899px) and (min-width: 600px) {
    .actividades_title{position:relative;transform:translateY(0)!important;width:100%;padding:2rem;}
    .actividades_title_title{font-size:2.5rem;line-height:2.2rem;}
    .actividades_title_sep{margin:.3rem 0;}
    .actividades_title_des{font-size:1.2rem;min-width:32rem;}

    .actividad{position:relative;min-height:20rem;}
    .actividad:nth-child(odd){justify-content:flex-start;}
    .actividad:nth-child(even){justify-content:flex-end;}
    .actividad_image{position:absolute;width:52%;height:100%;top:0;}
    .actividad_image img{width:100%;height:100%;object-fit:cover;}
    .actividad_image:nth-child(odd){left:0;}
    .actividad_image:nth-child(even){right:0;}
    .actividad_des{padding:2rem;}
    .actividad_des_title{font-size:2.2rem;line-height:2.2rem;margin-bottom:.3rem;}
    .actividad_des_des{font-size:1rem;margin-bottom:.4rem;overflow-wrap:break-word;}
    .actividad_des_des:last-child{margin-bottom:0;}
}

@media (max-width:1199px) and (min-width: 900px) {
    .actividades_title{padding:3rem 2rem;}
    .actividades_title_title{font-size:2.2rem;line-height:2.2rem;}
    .actividades_title_sep{margin:.3rem 0;}
    .actividades_title_des{font-size:1.1rem;}

    .actividad{position:relative;min-height:20rem;}
    .actividad:nth-child(odd){justify-content:flex-start;}
    .actividad:nth-child(even){justify-content:flex-end;}
    .actividad_image{position:absolute;width:52%;height:100%;top:0;}
    .actividad_image img{width:100%;height:100%;object-fit:cover;}
    .actividad_image:nth-child(odd){left:0;}
    .actividad_image:nth-child(even){right:0;}
    .actividad_des{padding:2rem;}
    .actividad_des_title{font-size:2.2rem;line-height:2.2rem;margin-bottom:.3rem;}
    .actividad_des_des{font-size:1rem;margin-bottom:.4rem;}
    .actividad_des_des:last-child{margin-bottom:0;}
}

@media (max-width:1399px) and (min-width: 1200px) {
    .actividades_title{padding:3rem;}
    .actividades_title_title{font-size:2.5rem;line-height:2.5rem;}
    .actividades_title_sep{margin:.3rem 0;}
    .actividades_title_des{font-size:1.2rem;max-width:32rem;}

    .actividad_des{padding:2rem;}
    .actividad_des_title{font-size:2.5rem;line-height:2.5rem;margin-bottom:.5rem;}
    .actividad_des_des{font-size:1.1rem;margin-bottom:.5rem;}
    .actividad_des_des:last-child{margin-bottom:0;}
}

@media (min-width:1400px) {
    .actividades_title{padding:2rem 3rem;}
    .actividades_title_title{font-size:2.5rem;line-height:2.5rem;}
    .actividades_title_sep{margin:.3rem 0;}
    .actividades_title_des{font-size:1.2rem;max-width:32rem;}

    .actividad_des{padding:3rem;}
    .actividad_des_title{font-size:2.5rem;line-height:2.5rem;margin-bottom:.5rem;}
    .actividad_des_des{font-size:1.1rem;margin-bottom:.5rem;max-width:45rem;}
    .actividad_des_des:last-child{margin-bottom:0;}
}