/* Entradas */
.entradas_title_con{color:#181c3f;text-align:center;}
.entradas_title_con_sep{display:block;height:.35rem;background-color:var(--yellow);margin:auto;}
.entradas_title_bg{position:absolute;top:0;left:0;height:100%;width:auto;}
.entradas_mantarraya{position:absolute;right:0;top:-5rem;overflow:hidden;width:100%;height:20rem;}
.mantarraya{position:absolute;z-index:2;margin-top:5rem;}

/* Entradas opciones */
.entradas{overflow:hidden;}
.entradas_content{display:flex;justify-content:center;align-items:stretch;}
.entradas_img{transform:translateX(-100%);opacity:0;}
.entradas_options{transform:translateX(100%);opacity:0;background: rgb(23,95,144);background: linear-gradient(0deg, rgba(23,95,144,1) 100%, rgba(21,141,195,1) 0%);display:flex;align-items:center;}
.entradas_img,.entradas_options{width:50%;}
.accordion_entradas_item{background-color:#fff!important;}
.entradas_options{color:#fff;}
.entradas_options_btn{text-decoration:none;background-color:var(--yellow);color:var(--navy);font-weight:bold;padding:.3rem 1rem;display:block;width:fit-content;}
.entradas_options_t3 a{text-decoration:none;color:#fff;}

/* Entradas membresias */
.membresias_content{overflow: hidden;}
.entradas_membresias{margin-top:2rem;display:flex;align-items:stretch;justify-content:center;color:#fff;position:relative;}
.entradas_membresias_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.entradas_membresias_img{transform:translateX(-100%);opacity:0;}
.entradas_membresias_con{transform:translateX(100%);opacity:0;background-color:#24b9e5;display:flex;align-items:center;}
.entradas_membresias_con_des{position:relative;z-index:2;}
.entradas_membresias_img, .entradas_membresias_con{width:50%;z-index:2;}
.entradas_membresias_con_t{margin-top:1rem;}
.entradas_membresias_con_btn{display:block;width:fit-content;background-color:var(--yellow);text-decoration:none;color:var(--navy);font-weight:bold;padding:.3rem 1rem;margin-top:1rem;}
.accordion_especiales_item, .accordion_heading{border-bottom:1px solid #fff;}
.accordion_especiales_item:last-child, .accordion_heading{border-bottom:0;}
.accordion_button_especiales{color:#fff;}
.cotorro_con{position:absolute;z-index:3;overflow:hidden;}

/* Responsive style */
@media (max-width: 599px){
    .entradas_title_bg{width:100%;height:100%;}
    .entradas_title{padding-top:3rem;padding-bottom:3rem;}
    .entradas_title_con_title{font-size:3rem;line-height:2rem;letter-spacing:1px;}
    .entradas_title_con_sep{width:8rem;margin-top:1rem;margin-bottom:1rem;}
    .entradas_title_con_p{font-size:1rem;}
    .mantarraya{width:22rem;top:0;right:-10rem;}

    .entradas_content{flex-direction:column-reverse;}
    .entradas_img,.entradas_options{width:100%;}
    .entradas_options{padding:2rem 1rem;}
    .entradas_options_con{width:100%;}
    .entradas_options_btn{margin:1rem 0;font-size:1.2rem;}
    .entradas_options_t1{font-size:1.1rem;}
    .entradas_options_t2{font-size:1.1rem;margin-bottom:.5rem;}

    .entradas_membresias_img{display:none;}
    .entradas_membresias_con{width:100%;}
    .membresias_content{padding-left:0;padding-right:0;padding-bottom:0;}
    .entradas_membresias_con{padding:2rem 1rem 3rem 1rem;}
    .entradas_membresias_con_des{width:100%;}
    .entradas_membresias_con_t,.entradas_membresias_con_btn{font-size:1.2rem;}
    .cotorro_con{width:6rem;height:auto;right:0;bottom:-1rem;}
    .cotorro{transform:scaleX(-1) translateX(-1.5rem);}
}

@media (max-width:899px) and (min-width: 600px){
    .entradas_title{padding-top:3rem;padding-bottom:3rem;}
    .entradas_title_con_title{font-size:3.5rem;line-height:2.5rem;letter-spacing:1px;}
    .entradas_title_con_sep{width:9rem;margin-top:1rem;margin-bottom:1rem;}
    .entradas_title_con_p{font-size:1.3rem;}
    .mantarraya{width:25rem;top:0;right:-10rem;}

    .entradas_content{flex-direction:column-reverse;}
    .entradas_img,.entradas_options{width:100%;}
    .entradas_options{padding:3rem;}
    .entradas_options_con{width:100%;}
    .entradas_options_btn{margin:1rem 0;font-size:1.3rem;}
    .entradas_options_t1{font-size:1.2rem;}
    .entradas_options_t2{font-size:1.2rem;margin-bottom:.5rem;}

    .entradas_membresias_img{display:none;}
    .entradas_membresias_con{width:100%;}
    .membresias_content{padding-left:0;padding-right:0;padding-bottom:0;}
    .entradas_membresias_con{padding:3rem 3rem 5rem 3rem;}
    .entradas_membresias_con_des{width:100%;}
    .entradas_membresias_con_t,.entradas_membresias_con_btn{font-size:1.2rem;}
    .cotorro_con{width:8rem;height:auto;right:0;bottom:-1.3rem;}
    .cotorro{transform:scaleX(-1) translateX(-2rem);}
}

@media (max-width:1199px) and (min-width: 900px){
    .entradas_title{padding-top:3rem;padding-bottom:3rem;}
    .entradas_title_con_title{font-size:4rem;line-height:3rem;letter-spacing:1px;}
    .entradas_title_con_sep{width:10rem;margin-top:1rem;margin-bottom:1rem;}
    .entradas_title_con_p{font-size:1.5rem;}
    .mantarraya{width:28rem;top:0;right:-8rem;}

    .entradas_options{padding:1.5rem;}
    .entradas_options_btn{margin:.5rem 0;font-size:1.2rem;}
    .entradas_options_t1{font-size:1.1rem;}
    .entradas_options_t2{font-size:1.1rem;margin-bottom:.5rem;}

    .membresias_content{padding-left:2.5rem;padding-right:2.5rem;}
    .entradas_membresias_con{padding:1.5rem;}
    .entradas_membresias_con_t,.entradas_membresias_con_btn{font-size:1.2rem;}
    .cotorro_con{width:11rem;height:auto;left:0;bottom:-1.4rem;}
    .cotorro{transform:translateX(-3rem);}
}

@media (max-width:1399px) and (min-width: 1200px){
    .entradas_title{padding-top:3rem;padding-bottom:3rem;}
    .entradas_title_con_title{font-size:4rem;line-height:3rem;letter-spacing:1px;}
    .entradas_title_con_sep{width:10rem;margin-top:1rem;margin-bottom:1rem;}
    .entradas_title_con_p{font-size:1.5rem;}
    .mantarraya{width:32rem;top:0;right:-8rem;}

    .entradas_options{padding:2rem;}
    .entradas_options_btn{margin:.5rem 0;font-size:1.2rem;}
    .entradas_options_t1{font-size:1.1rem;}
    .entradas_options_t2{font-size:1.1rem;margin-bottom:.5rem;}

    .entradas_membresias_con{padding:2rem;}
    .entradas_membresias_con_t,.entradas_membresias_con_btn{font-size:1.2rem;}
    .cotorro_con{width:13rem;height:auto;left:0;bottom:-1.7rem;}
    .cotorro{transform:translateX(-3rem);}
}

@media (min-width:1400px){
    .entradas_title{padding-top:4rem;padding-bottom:4rem;}
    .entradas_title_con_title{font-size:5rem;line-height:4rem;letter-spacing:2px;}
    .entradas_title_con_sep{width:13rem;margin-top:1rem;margin-bottom:1rem;}
    .entradas_title_con_p{font-size:2rem;}
    .mantarraya{width:36rem;top:0;right:-8rem;}

    .entradas_options{padding:2rem;}
    .accordion_entradas,.entradas_options_btn,.entradas_options_t1,.entradas_options_t2,.entradas_options_t3{max-width:41rem;}
    .entradas_options_btn{margin:.5rem 0;font-size:1.3rem;}
    .entradas_options_t1{font-size:1.3rem;}
    .entradas_options_t2{font-size:1.3rem;margin-bottom:.5rem;}

    .entradas_membresias_con{padding:2rem;}
    .entradas_membresias_con_t,.entradas_membresias_con_btn{font-size:1.3rem;}
    .cotorro_con{width:14rem;height:auto;left:0;bottom:-1.7rem;}
    .cotorro{transform:translateX(-3rem);}
}